const TestEventId = () => {
    const handleMetadataSubmit = (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const eventId = formData.get("eventId");

        fetch(`/api/events/${eventId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    return (
        <div>
            <h4>Metadata</h4>
            <form onSubmit={handleMetadataSubmit}>
                <div className="mb-3">
                    <label htmlFor="id" className="form-label">Event ID</label>
                    <input type="text" className="form-control" id="id" name="eventId" />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default TestEventId;